import React from "react";
import { View, StyleSheet } from "react-native";

import { useTheme } from "@smartrent/ui";

export const AppContainer: React.FC = ({ children }) => {
  const { colors } = useTheme();

  return (
    <View
      style={[
        styles.root,
        {
          backgroundColor: colors.pageBackground,
        },
      ]}
    >
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
});
