import axios, { AxiosError } from "axios";

export const isAxiosError = (err: any): err is AxiosError => {
  return typeof err === "object" && err.isAxiosError;
};

export const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/v1/public`,
});

apiClient.interceptors.request.use((config) => {
  const authStateStr = window.localStorage.getItem("authState");
  if (!authStateStr) {
    return config;
  }

  try {
    const authState = JSON.parse(authStateStr);

    if (!authState) {
      return config;
    }

    const accessToken =
      typeof authState === "object" &&
      "accessToken" in authState &&
      authState.accessToken;

    return {
      ...config,
      headers: accessToken
        ? {
            authorization: `Bearer ${accessToken}`,
            ...config.headers,
          }
        : config.headers,
    };
  } catch (err) {
    console.error(err);
    return config;
  }
});
