import React, { Suspense } from "react";
import { StyleSheet, View } from "react-native";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import loadable from "@loadable/component";
import * as Sentry from "@sentry/react";

import { reactQueryConfig } from "@smartrent/hooks";
import { ThemeProvider, themes, Skeleton } from "@smartrent/ui";

import { AuthProvider } from "./context/Auth";
import { AppContainer } from "./components/layout/AppContainer";
import { SentryError } from "./components/SentryError";

const LandingScreen = loadable(() => import("./pages/LandingScreen"));
const LookupResult = loadable(() => import("./pages/LookupResult"));
const SignRouter = loadable(() => import("./pages/guest-parking/SignRouter"));
const SessionRouter = loadable(
  () => import("./pages/guest-parking/SessionRouter")
);
const PropertyRouter = loadable(
  () => import("./pages/guest-parking/PropertyRouter")
);
const TermsOfService = loadable(
  () => import("./pages/guest-parking/TermsOfService")
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      ...reactQueryConfig.defaultOptions?.queries,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    },
  },
});

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={themes.smartrent}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <AuthProvider>
            <AppContainer>
              <Sentry.ErrorBoundary fallback={<SentryError />}>
                <Suspense
                  fallback={
                    <View style={styles.loader}>
                      <Skeleton width={20} circle />
                    </View>
                  }
                >
                  <Switch>
                    <Route path="/v/:decal" component={LookupResult} />
                    <Route path="/s/:signId" component={SignRouter} />
                    <Route path="/gs/:sessionId" component={SessionRouter} />
                    <Route
                      path="/terms-of-service"
                      component={TermsOfService}
                    />
                    <Route path="/:propertySlug" component={PropertyRouter} />
                    <Route path="/" component={LandingScreen} />
                  </Switch>
                </Suspense>
              </Sentry.ErrorBoundary>
            </AppContainer>
          </AuthProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

const styles = StyleSheet.create({
  loader: {
    alignItems: "center",
  },
});

export default App;
