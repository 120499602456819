import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";

let defaultStorage: Storage;

try {
  // ensure localStorage is available in case we're running under iOS Safari's
  // private browsing mode
  const testKey = `test-${Math.random()}`;
  window.localStorage.setItem(testKey, "test");
  window.localStorage.removeItem(testKey);
  defaultStorage = window.localStorage;
} catch (err) {
  defaultStorage = window.sessionStorage;
}

export function useStorage<V>(
  key: string,
  defaultValue: V,
  storageArea?: Storage
): [V, Dispatch<SetStateAction<V | undefined>>, () => void];

export function useStorage<V>(
  key: string,
  defaultValue?: V,
  storageArea?: Storage
): [V | undefined, Dispatch<SetStateAction<V | undefined>>, () => void];

export function useStorage<V>(
  key: string,
  defaultValue?: V,
  storageArea = defaultStorage
): [V | undefined, Dispatch<SetStateAction<V | undefined>>, () => void] {
  const [value, setValue] = useState<V | undefined>(() => {
    const value = storageArea.getItem(key);
    if (!value) {
      return defaultValue;
    }

    try {
      const parsedValue = JSON.parse(value);
      return parsedValue;
    } catch (err) {
      return defaultValue;
    }
  });

  const updateValue: Dispatch<SetStateAction<V | undefined>> = useCallback(
    (valOrFn) => {
      setValue((oldValue) => {
        const nextValue =
          typeof valOrFn === "function" ? (valOrFn as any)(oldValue) : valOrFn;
        storageArea.setItem(key, JSON.stringify(nextValue));
        return nextValue;
      });
    },
    [key, storageArea]
  );

  const removeValue = useCallback(() => {
    setValue(defaultValue);
    storageArea.removeItem(key);
  }, [defaultValue, key, storageArea]);

  // listen to storage events and update state when the value changes
  useEffect(() => {
    const eventHandler = (event: StorageEvent) => {
      if (event.key !== key || event.storageArea !== storageArea) {
        return;
      }

      if (typeof event.newValue === "undefined" || event.newValue === null) {
        setValue(defaultValue);
        return;
      }

      const parsedValue = JSON.parse(event.newValue);
      setValue(parsedValue);
    };

    window.addEventListener("storage", eventHandler);

    return () => {
      window.removeEventListener("storage", eventHandler);
    };
  }, [defaultValue, key, storageArea]);

  return [value, updateValue, removeValue];
}
