import React from "react";
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";

import { ExclamationCircleOutline } from "@smartrent/icons";
import { Typography, useTheme } from "@smartrent/ui";

export interface NoRecordsProps {
  title?: React.ReactNode;
  text?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}

export const NoRecords: React.FC<NoRecordsProps> = ({
  title = "No Records Found",
  text,
  style,
}) => {
  const { colors } = useTheme();

  return (
    <View style={[styles.root, style]}>
      <ExclamationCircleOutline size={72} color={colors.primary} />
      {React.isValidElement(title) ? (
        title
      ) : (
        <Typography type="title" color="primary" style={styles.text}>
          {title}
        </Typography>
      )}
      {text ? (
        React.isValidElement(text) ? (
          text
        ) : (
          <Typography style={styles.text}>{text}</Typography>
        )
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 48,
  },
  text: {
    textAlign: "center",
  },
});
