import { apiClient } from "@/lib/api";
import { createAxiosMutation } from "@/hooks/react-query";

interface VerifyPhoneMutationOptions {
  phone: string;
  otp: string;
}

interface AccessTokenResponse {
  access_token: string;
  refresh_token: string;
}

export const useLoginMutation = createAxiosMutation(async (phone: string) => {
  return apiClient.post("/auth/login", {
    phone_number: phone,
    tos_accepted: true,
  });
});

export const useVerifyPhoneMutation = createAxiosMutation(
  async ({ phone, otp }: VerifyPhoneMutationOptions) => {
    return apiClient.post<AccessTokenResponse>("/auth/verify", {
      phone_number: phone,
      otp,
    });
  }
);

export const useRefreshTokenMutation = createAxiosMutation(
  async (refreshToken: string) => {
    return apiClient.post<AccessTokenResponse>("/auth/refresh", {
      refresh_token: refreshToken,
    });
  }
);
