import * as Sentry from "@sentry/react";

import { isAxiosError } from "./api";

if (process.env.REACT_APP_SENTRY_DSN) {
  const isApiResponseError = (err: any): boolean => {
    if (!isAxiosError(err)) {
      return false;
    }

    return typeof err.response?.status === "number";
  };

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_RUNTIME_ENV,
    ignoreErrors: [
      "ResizeObserver loop limit exceeded",
      "ReferenceError: 'Promise' is undefined",
      "SecurityError: Blocked a frame with origin",
      "Loading chunk",
      "Route not found: ",
      "The operation is insecure.",
    ],
    beforeSend: (event, hint) => {
      // filter out axios errors that have a response status code
      if (isApiResponseError(hint?.originalException)) {
        return null;
      }

      return event;
    },
  });
}
