import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";

import { isPast, subSeconds } from "date-fns";
import decodeJwt from "jwt-decode";

import { useInterval, useQueryCompat } from "@smartrent/hooks";

import { useStorage } from "@/hooks/storage";
import { apiClient, isAxiosError } from "@/lib/api";
import { useRefreshTokenMutation } from "@/queries/auth";

function isSoon(targetDate: Date, thresholdSeconds: number): boolean {
  return isPast(subSeconds(targetDate, thresholdSeconds));
}

function getTokenExpiration(token: string): Date {
  const payload = decodeJwt<Record<string, any>>(token);

  if (typeof payload !== "object" || !payload || !("exp" in payload)) {
    throw new Error("Invalid token");
  }

  return new Date(payload.exp * 1000);
}

interface AuthState {
  phoneNumber: string | null;
  accessToken: string | null;
  refreshToken: string | null;
}

const initialState = {
  phoneNumber: null,
  accessToken: null,
  refreshToken: null,
};

const AuthStateContext = createContext<AuthState>(initialState);

interface AuthActions {
  logout: () => void;
  refresh: () => Promise<void>;
  setPhoneNumber: (phoneNumber: string) => void;
  setTokens: (accessToken: string, refreshToken: string) => void;
}

const AuthActionsContext = createContext<AuthActions>({
  logout: () => undefined,
  refresh: () => Promise.resolve(),
  setPhoneNumber: () => undefined,
  setTokens: () => undefined,
});

export const AuthProvider: React.FC = ({ children }) => {
  const [doRefresh] = useRefreshTokenMutation();

  const [state, setState, clearState] = useStorage<AuthState>(
    "authState",
    initialState
  );

  const setPhoneNumber = useCallback(
    (phoneNumber: string) => setState({ ...state, phoneNumber }),
    [setState, state]
  );

  const setTokens = useCallback(
    (accessToken: string, refreshToken: string) =>
      setState({ ...state, accessToken, refreshToken }),
    [setState, state]
  );

  const refresh = useCallback(async () => {
    if (!state.accessToken || !state.refreshToken) {
      return;
    }

    // we only want to refresh the access token if it is already expired or
    // will be expiring soon
    const accessTokenExp = getTokenExpiration(state.accessToken);
    if (!isSoon(accessTokenExp, 30)) {
      return;
    }

    // don't bother trying to use an expired refresh token
    const refreshTokenExp = getTokenExpiration(state.refreshToken);
    if (isPast(refreshTokenExp)) {
      return clearState();
    }

    try {
      const response = await doRefresh(state.refreshToken);
      if (!response) {
        return clearState();
      }

      setTokens(response.data.access_token, response.data.refresh_token);
    } catch (err) {
      console.error(err);
      return clearState();
    }
  }, [clearState, doRefresh, setTokens, state.accessToken, state.refreshToken]);

  // TODO: https://github.com/smartrent/js-shared/pull/292
  useInterval(refresh, (state.accessToken ? 30000 : null) as any);

  // see if we need to refresh the access token when the component first mounts
  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on first render
  }, []);

  // when we have an access token, fetch the current user (to make sure the token is valid)
  const { error: fetchUserError } = useQueryCompat(
    ["me"],
    async (key: "me") => {
      const response = await apiClient.get("/auth/me");
      return response.data;
    },
    {
      enabled: !!state.accessToken,
    }
  );

  useEffect(() => {
    if (!fetchUserError || !isAxiosError(fetchUserError)) {
      return;
    }

    if (fetchUserError.response?.status === 401) {
      return clearState();
    }
  }, [clearState, fetchUserError]);

  const actions = useMemo(
    () => ({
      logout: clearState,
      refresh,
      setPhoneNumber,
      setTokens,
    }),
    [clearState, refresh, setPhoneNumber, setTokens]
  );

  return (
    <AuthActionsContext.Provider value={actions}>
      <AuthStateContext.Provider value={state}>
        <>{children}</>
      </AuthStateContext.Provider>
    </AuthActionsContext.Provider>
  );
};

export const useAuthActions = () => useContext(AuthActionsContext);
export const useAuthState = () => useContext(AuthStateContext);

export interface AuthSwitchProps {
  /**
   * The component to render if the user is authenticated.
   */
  authenticated?: React.ComponentType | React.ReactElement;

  /**
   * The component to render if the user is **not** authenticated.
   */
  unauthenticated?: React.ComponentType | React.ReactElement;
}

export const AuthSwitch: React.FC<AuthSwitchProps> = ({
  authenticated,
  unauthenticated,
}) => {
  const { accessToken } = useAuthState();

  let ComponentOrElement: React.ComponentType | React.ReactElement;

  if (authenticated && accessToken) {
    ComponentOrElement = authenticated;
  } else if (unauthenticated && !accessToken) {
    ComponentOrElement = unauthenticated;
  } else {
    return null;
  }

  if (React.isValidElement(ComponentOrElement)) {
    return ComponentOrElement;
  }

  return <ComponentOrElement />;
};
