import React, { useCallback } from "react";
import { View, StyleSheet } from "react-native";

import { Button } from "@smartrent/ui";

import { NoRecords } from "./NoRecords";

export const SentryError: React.FC = () => {
  const handleReload = useCallback(() => {
    window.location.reload();

    return false;
  }, []);

  return (
    <View style={styles.error}>
      <NoRecords title="Something Went Wrong" />
      <Button onPress={handleReload}>Reload Page</Button>
    </View>
  );
};

const styles = StyleSheet.create({
  error: {
    flex: 1,
    padding: 8,
    alignItems: "center",
    justifyContent: "center",
  },
});
